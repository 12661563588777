<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    商品详情活动
                </div>
            </template>
            <el-scrollbar height="600px">
                <div class="content">
                    <span style="font-size:14px;margin-right:10px;">活动标题:</span>
                    <el-input v-model="title" placeholder="活动标题" prefix-icon="el-icon-search" class="hidden-sm-and-down"
                        style="width:200px" size="small"></el-input>
                    <span style="font-size:14px;margin: 0 10px;">活动位置:</span>
                    <el-select v-model="activity" style="width:120px;" size="small">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="平台活动" value="1"></el-option>
                        <el-option label="商品活动" value="2"></el-option>
                        <el-option label="服务保障" value="3"></el-option>
                    </el-select>
                    <span style="font-size:14px;margin: 0 10px;">启用状态:</span>
                    <el-select v-model="status" style="width:120px;" size="small">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="启用" value="1"></el-option>
                        <el-option label="停用" value="2"></el-option>
                    </el-select>
                    <span style="font-size:14px;margin: 0 10px;">跳转位置:</span>
                    <el-select v-model="jump" style="width:120px;" size="small">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="页面跳转" value="1"></el-option>
                        <el-option label="商品" value="2"></el-option>
                        <el-option label="商品集合页" value="3"></el-option>
                        <el-option label="活动页链接" value="4"></el-option>
                    </el-select>
                    <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down"
                        size="small">搜索</el-button>
                    <el-button type="success" @click="add" style="margin-left:10px;" class="hidden-sm-and-down"
                        size="small">新增</el-button>
                </div>
                <div class="content-main">
                    <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                        element-loading-spinner="el-icon-loading" element-loading-text="加载中..."
                        :default-sort="{ prop: 'AddDTime', order: 'descending' }" size="small">
                        <el-table-column label="活动标题" prop="title">
                        </el-table-column>
                        <el-table-column label="活动图片">
                            <template #default="scope">
                                <el-image style="width: 100px; height: 100px" :preview-src-list="[scope.row.imageUrl]"
                                    :src="scope.row.imageUrl" />
                            </template>
                        </el-table-column>
                        <el-table-column label="活动位置">
                            <template #default="scope">
                                <text v-if="scope.row.activity == 1">平台活动</text>
                                <text v-if="scope.row.activity == 2">商品活动</text>
                                <text v-if="scope.row.activity == 3">服务保障</text>
                            </template>
                        </el-table-column>
                        <el-table-column label="选择跳转">
                            <template #default='scope'>
                                <text v-if="scope.row.jump == 1">页面跳转</text>
                                <text v-if="scope.row.jump == 2">商品</text>
                                <text v-if="scope.row.jump == 3">商品集合页</text>
                                <text v-if="scope.row.jump == 4">活动页链接</text>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品范围">
                            <template #default='scope'>
                                <text v-if="scope.row.goodsrang == 1">指定商品</text>
                                <text v-if="scope.row.goodsrang == 2">指定品类</text>
                                <text v-if="scope.row.goodsrang == 3">指定品类+品牌</text>
                                <text v-if="scope.row.goodsrang == 4">指定品类+品牌+型号</text>
                                <text v-if="scope.row.goodsrang == 5">所有品牌</text>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="适用商户" prop="Status">
                                <template #default='scope'>
                                    <text v-if="scope.row.business == 1">全部商户</text>
                                    <text v-if="scope.row.business == 2">
                                        部分商户
                                        <el-button @click="look(scope.row.applycontent)" style="display: inline-flex;align-items: center;border-color: transparent;background-color:white;color:#409eff;padding: 2px;height: auto;">点击查看</el-button>
                                    </text>
                                </template>
                            </el-table-column> -->
                        <el-table-column label="状态">
                            <template #default='scope'>
                                <span v-if="scope.row.status == 1" style="color:#67c23a">启用</span>
                                <span v-if="scope.row.status == 2" style="color:red">停用</span>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作" align="left">
                            <template #default="scope">
                                <el-button type="primary" @click="edit(scope.row.id)"
                                    style="display: inline-flex;align-items: center;border-color: transparent;background-color:white;color:#409eff;padding: 2px;height: auto;">编辑</el-button>
                                <el-button type="primary" @click="del(scope.row.id)"
                                    style="display: inline-flex;align-items: center;border-color: transparent;background-color:white;color:#409eff;padding: 2px;height: auto;">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                        :current-page="curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                        layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="false"
                        style="text-align:center"></el-pagination>
                </div>
            </el-scrollbar>
        </el-card>
    </div>
    <!--查看商户-->
    <el-dialog v-model="lookis" title="商户" width="30%">
        <el-tag style="margin-right: 10px;" v-for="val in itemss" :key="val['ID']">{{ val['Merchant'] }}</el-tag>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="lookis = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--查看商户-->
</template>
<style>
.el-card {
    padding: 0 !important;
}

.el-card__body {
    padding: 10px;
}

.el-card__header {
    padding: 10px !important;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}
</style>
<script>
import constant from "@/constant"
// import { ref } from "vue";
import { ElMessage } from 'element-plus'
import { ref } from 'vue';
export default {
    data() {
        return {
            lookis: ref(false),
            itemss: [],
            load: false,
            title: ref(""),
            tianjia: ref(false),
            dialogImageUrl: ref(''),
            dialogVisible: ref(false),
            disabled: ref(false),
            activity: ref(""),
            jump: ref(),//跳转
            scope: ref(),//商品范围
            business: ref(),//选择商户
            status: ref(),
            tabledata: [],
            curpage: 1,
            pagesize: 10,
            totalcount: 0,
        }
    },
    components: {
    },
    created: function () {
        this.init();
    },
    methods: {
        look(val) {
            this.itemss = []
            this.lookis = true
            console.log(val)
            this.axios.get(constant.lookmerchant, {
                headers: {
                    "Content-Type": 'application/json'
                },
                params: {
                    id: val
                }
            }).then((response) => {
                console.log(response.data)
                this.itemss = response.data
            })
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        edit(id) {
            console.log(id)
            this.$router.push({ path: '/addproductdetailactivity', query: { id: id } })
        },
        del(id) {
            console.log(id);
            this.axios.get(constant.deldetail, {
                headers: {
                    "Content-Type": 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data)
                let typenew = "";
                if (response.data.code == 200) {
                    typenew += "success"
                } else {
                    typenew += "error"
                }
                ElMessage({
                    type: typenew,
                    message: response.data.msg
                })
                setTimeout(function () {
                    window.location.reload()
                }, 2000)
            })
        },
        init() {
            this.axios.get(constant.goodsdetail, {
                headers: {
                    "Content-Type": 'application/json'
                },
                params: {
                    title: this.title,
                    activity: this.activity,
                    status: this.status,
                    jump: this.jump,
                    curpage: this.curpage,
                    pagesize: this.pagesize
                }
            }).then((response) => {
                console.log(response.data)
                this.tabledata = response.data.list
                this.totalcount = response.data.totalcount * 1;
                this.curpage = response.data.curpage * 1;
                this.load = false;
            })
        },
        add() {
            this.$router.push({ path: "/addproductdetailactivity" })
        },
        handleRemove(file) {
            console.log(file)
        },
        handlePictureCardPreview(file) {
            console.log(file)
        },
        handleDownload(file) {
            console.log(file)
        }
    }
}
</script>